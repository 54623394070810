<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t("user.head_register") }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("user.register") }} </v-card-title>

        <v-form ref="form" v-model="formValid">
          <v-divider class="mx-3" />
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field
                  v-model="datas.username"
                  :label="'username ('+ $t('user.tel') + ')'"
                  :rules="formRule.username"
                  outlined
                  dense
                  required
                />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field
                  v-model="datas.password"
                  label="password"
                  :rules="formRule.password"
                  outlined
                  dense
                  required
                />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field
                  v-model="datas.fullName"
                  :rules="formRule.fullName"
                  :label="$t('user.fullname')"
                  outlined dense
                />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" xl="4" v-if="bankCountry === 'TH'">
                <v-select
                  v-model="datas.bank"
                  :label="$t('user.bank')"
                  :rules="formRule.bank"
                  :items="bankItems"
                  item-text="label"
                  outlined
                  dense
                  required
                />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4" v-if="bankCountry === 'KR' || bankCountry === 'LA'" >
                <v-select
                  v-model="datas.bank_code"
                  :label="$t('user.bank')"
                  :rules="formRule.bank"
                  :items="bankItems"
                  item-text="label"
                  outlined
                  dense
                  required
                />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.bank_number" :rules="formRule.bank_number" :label="$t('user.bank_account')" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field v-model="datas.bank_name" :rules="formRule.bank_name" :label="$t('user.account_name')" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" xl="4">
                <v-text-field
                  v-model="datas.email"
                  :rules="formRule.email"
                  type="email"
                  :label="$t('user.email')"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-select v-model="datas.type" :label="$t('partner')" :items="partnerItems" outlined dense />
              </v-col>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-select v-model="partnerName" :label="$t('partner_under')" :items="partnerItemsName" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-row>
              <v-col cols="12" xs="12" md="4" lg="4">
                <v-select v-model="datas.country_code" :label="$t('user.country')" :items="countryCodeItems" outlined dense />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'user' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back')}}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="createUser" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{$t('user.user_create')}}</span></v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { KRBanks } from '@/utils/KRBanks.ts'
import { LABanks } from '@/utils/LABanks.ts'
import { KHBanks } from '@/utils/KHBanks.ts'

export default {
  data() {
    return {
      formValid: true,
      formRule: {
        username: [
          value => !!value || 'username is required.',
          value => (!!value && value.indexOf(' ') < 0) || 'must have no spaces',
          value => ((!!value && value.length >= 8) && (!!value && value.length <= 16)) || 'need phone 8-16 characters',
          value => /^\d+$/.test(value)
        ],
        password: [
          value => !!value || 'password is required.',
          value => (!!value && value.length > 5) || 'need more than 5 characters',
        ],
        email: [
          value => !!value || 'email is required.',
          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email must be valid'
        ],
        fullName:[
          value => !!value || 'full name is required.',
        ],
        bank: [
          value => !!value || 'bank is required.',
        ],
        bank_number: [
          value => !!value || 'bank number is required.',
        ],
        bank_name: [
          value => !!value || 'bank account is required.',
        ],
      },
      datas: {
        fullName: '',
        username: null,
        password: null,
        email: null,
        line: null,
        country_code: '',
        type: 'member'
      },
      breadcrumbsItems: [
        {
          text: this.$t('user.head_list'),
          disabled: false,
          href: '/user',
        },
        {
          text: this.$t('user.add_member'),
          disabled: true,
          href: '',
        },
      ],
      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('no'),
          value: 'no',
        },
      ],
      loading: false,
      roles: [],
      bankItems: [],
      THBanks: [],
      partnerItems: [
        {
          text: this.$t('yes'),
          value: 'partner',
        },
        {
          text: this.$t('not'),
          value: 'member',
        },
      ],
      partnerItemsName: [{text: this.$t('no'), value: null}],
      partnerName: null,
      countryCodeItems: [
        // {text: 'ไม่มี', value: 0},
        {text: 'เกาหลี (Korea - KR)', value: 'KR'},
        {text: 'ไทย (Thai - TH)', value: 'TH'},
        {text: 'อังกฤษ (England - EN)', value: 'EN'},
        {text: 'เวียดนาม (Vietnam - VN)', value: 'VN'},
        {text: 'พม่า (Myanmar - MM)', value: 'MM'},
        {text: 'กัมพูชา (Cambodia - KH)', value: 'KH'}],
      defaultRef: [],
      bankCountry: '',
    }
  },
  async created() {
    try {
      await this.$store.dispatch('getTypeBankData').then(result => {
        this.THBanks = result
      })

      this.roles = await this.$store.dispatch('getRoleData')
      let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
      this.bankSystem = settingWebInfo.bank_system;
      this.defaultRef = settingWebInfo.default_ref;
      this.bankSystem = settingWebInfo.bank_system;

      await this.$store.dispatch('getPartnerour', 1).then(result => {
        result.data.map(el => this.partnerItemsName.push({ text: el.name, value: el.refKey }))
      })
    } catch (e) {}

    await this.addLogPage()
    await this.getBankList()
  },
  methods: {
    async addLogPage(){
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'สมาชิก',
            url: window.location.href,
            detail: 'เพิ่มสมาชิก',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )

        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getBankList(){
      let bankList;
      let bankCountry = this.bankSystem[0] || 'TH';
      this.bankCountry = bankCountry;
      switch(bankCountry) {
        case 'LA':
          bankList = LABanks
          break;
        case 'KR':
          bankList = KRBanks
          break;
        case 'KH':
          bankList = KHBanks
          break;
        default:
          bankList = this.THBanks
        break;
      }
      const formattedBanks = bankList.map(bank => ({
        label: bankCountry === 'TH' ? bank.label :`${bank.bank_name} (${bank.bank_name_en})`,
        value: bankCountry === 'TH' ? bank.value : bank.bank_code,
      }));
      this.bankItems = formattedBanks
      this.datas.country_code = bankCountry
      this.datas.country_code = this.countryCodeItems.filter(item => item.value === this.datas.country_code)[0]?.value;
    },
    async createUser() {
      this.loading = true

      if (this.$refs.form.validate()) {
        let { username, password, email, line, ref, bank, bank_number, bank_name, bank_code, country_code, type } = this.datas

        if (this.partnerName) {
          ref = this.partnerName
        }

        console.log(ref, this.partnerName)

        if (!ref && (this.defaultRef && this.defaultRef.length > 0)) {
          let hostname = window.location.hostname;
          const domainList = this.defaultRef;
          let filterDomain = domainList.find((item) => hostname.includes(item.domain))
          if (!filterDomain) {
            filterDomain = domainList[0]
          }

          ref = filterDomain.refCode
        }

        const data = {
          username: String(username),
          password: password.toString(),
          name: String(bank_name),
          email: String(email),
          phone: String(username),
          line: line,
          bank: bank ? bank.toString() : null,
          bank_code: bank_code ? String(bank_code) : null,
          bank_name: String(bank_name),
          bank_account: bank_number,
          amount_deposit_auto: 0,
          type: type,
          ref_member: ref || null,
          created_at: Date.now(),
          // otp: this.otp,
          country_code: country_code || null
        };

        let params = data
        try {
          await this.$store.dispatch('addUser', params)
          this.$swal
            .fire({
              icon: 'success',
              text: this.$t('user.create_member_completed'),
              showCancelButton: false,
              confirmButtonText: this.$t('confirm'),
            })
            .then(result => {
              this.$router.push({ name: 'user' })
            })
        } catch (e) {
          if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      } else {
      }
      this.loading = false
    },
    onChangePartner($event) {
      console.log($event)
      if ($event !== 'ทั้งหมด') {

      } else {
      }
    },
  },
}
</script>
