export const LABanks = [
  { bank_code: '01', short_code: 'jdb', bank_name: 'ທະນາຄານ ຮ່ວມພັດທະນາ', bank_name_en: 'Joint Development Bank' },
  { bank_code: '02', short_code: 'ldb', bank_name: 'ທະນາຄານ ພັດທະນາລາວ', bank_name_en: 'Lao Development Bank' },
  { bank_code: '03', short_code: 'bcel', bank_name: 'ທະນາຄານ ການຄ້າຕ່າງປະເທດລາວ ມະຫາຊົນ', bank_name_en: 'Banque Pour Le Commerce Exterieur Lao Public' },
  { bank_code: '04', short_code: 'acb', bank_name: 'ທະນາຄານ ເອຊີລີດາ ລາວ', bank_name_en: 'Acleda Bank Lao' },
  { bank_code: '05', short_code: 'lvb', bank_name: 'ທະນາຄານ ຮ່ວມທຸລະກິດ ລາວ-ຫວຽດ', bank_name_en: 'LVB Bank' },
  { bank_code: '06', short_code: 'mj', bank_name: 'ທະນາຄານ ມາຣູຮານເຈແປນລາວ', bank_name_en: 'Maruhan Japan bank Lao' },
  { bank_code: '07', short_code: 'idb', bank_name: 'ທະນາຄານ ອີນໂດຈີນ', bank_name_en: 'Indochina Bank' },
  { bank_code: '08', short_code: 'sb', bank_name: 'ທະນາຄານ ຊາຄອມແບັງລາວ', bank_name_en: 'Sacom Bank' },
  { bank_code: '09', short_code: 'bfl', bank_name: 'ທະນາຄານ ລາວ ຝຮັ່ງ', bank_name_en: 'BFL Bank' },
  { bank_code: '10', short_code: 'bic', bank_name: 'ທະນາຄານ ບີໄອຊ', bank_name_en: 'BIC Bank' },
];
